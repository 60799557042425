import React, { useState, useEffect } from 'react'
import { Container, Section } from '../components/Common'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { BlogCard, FeaturedCard } from '../components/Blog'
import { useBlogPosts, useMeetTheTeam } from '../GraphQL/Queries'
import Select from 'react-select'
import BookBanner from '../components/BookBanner/BookBanner'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'

const Blog = () => {
    const data = useBlogPosts()
    const bookBanner = useMeetTheTeam()

    const { mostPopular, blogPosts, categories } = data

    const allBlogPosts = {
        popular: mostPopular.edges[0].node.posts.edges,
        all: blogPosts.edges,
        allCategories: categories.edges,
        featured: {
            title: blogPosts.edges[0].node.title,
            category: blogPosts.edges[0].node.acf_blogPostMeta.category.name,
            slug: blogPosts.edges[0].node.slug,
            excerpt: blogPosts.edges[0].node.acf_blog.blogExcerpt,
            image:
                blogPosts.edges[0].node.acf_blog.blogImage.sourceUrlSharp
                    .childImageSharp.fluid,
            alt: blogPosts.edges[0].node.acf_blog.blogImage.altText,
        },
        book: {
            title: bookBanner.acf_bookCTA.title,
            image:
                bookBanner.acf_bookCTA.image.sourceUrlSharp.childImageSharp
                    .fluid,
            alt: bookBanner.acf_bookCTA.image.altText,
        },
    }

    const [filterData, setFilterData] = useState(allBlogPosts.all)
    const [filterInput, setFilterInput] = useState({
        search: '',
        category: '',
    })
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage] = useState(6)

    const filterBlogPost = (input, category) => {
        let filter = allBlogPosts.all.filter(
            blog =>
                blog.node.title.toLowerCase().includes(input.toLowerCase()) &&
                blog.node.acf_blogPostMeta.category.slug.includes(category)
        )
        setFilterData(filter)
    }

    useEffect(() => {
        filterBlogPost(filterInput.search, filterInput.category)
    }, [filterInput])

    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage
    const currentPosts = filterData.slice(indexOfFirstPost, indexOfLastPost)

    const paginate = pageNumber => setCurrentPage(pageNumber)

    return (
        <Layout noHero pageClass="blog">
            <SEO title="Blog" />
            <Section>
                <Container fullBleed>
                    <div className="blog__main-section">
                        <div className="blog__featured">
                            <FeaturedCard
                                title={allBlogPosts.featured.title}
                                category={allBlogPosts.featured.category}
                                exerpt={allBlogPosts.featured.excerpt}
                                link={allBlogPosts.featured.slug}
                                image={allBlogPosts.featured.image}
                            />
                        </div>

                        <div className="blog__popular">
                            <h2 className="headline headline--h2">
                                MOST POPULAR
                            </h2>
                            <div className="divider divider--lg divider--thin"></div>

                            <div className="blog__popular-cards">
                                {allBlogPosts.popular.map(card => (
                                    <BlogCard
                                        title={card.node.title}
                                        category={
                                            card.node.acf_blogPostMeta.category
                                                .name
                                        }
                                        link={`${card.node.slug}`}
                                        image={
                                            card.node.acf_blog.blogImage
                                                .sourceUrlSharp.childImageSharp
                                                .fluid
                                        }
                                        key={card.node.slug}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </Container>
            </Section>

            <Section>
                <Container>
                    <div className="filter-section">
                        <div className="filter-section__header">
                            <h2 className="headline headline--h2">
                                LATEST POSTS
                            </h2>
                            <div className="filter-section__filters">
                                <div className="filter-section__input-wrapper">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="filter-section__input filter-section__input--text"
                                        value={filterInput.search}
                                        onChange={e => {
                                            setFilterInput({
                                                ...filterInput,
                                                search: e.target.value,
                                            })
                                        }}
                                    />
                                    <div className="icon icon--magnifing-glass"></div>
                                </div>
                                <div className="filter-section__input-wrapper filter-section__input-wrapper--select">
                                    <Select
                                        className="filter-section__input"
                                        placeholder="Choose a topic"
                                        options={allBlogPosts.allCategories.map(
                                            cat => ({
                                                value: cat.node.slug,
                                                label: cat.node.name,
                                            })
                                        )}
                                        isClearable
                                        onChange={e => {
                                            setFilterInput({
                                                ...filterInput,
                                                category: e ? e.value : '',
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="filter-section__cards">
                            {currentPosts.length ? (
                                currentPosts.map(card => (
                                    <BlogCard
                                        title={card.node.title}
                                        category={
                                            card.node.acf_blogPostMeta.category
                                                .name
                                        }
                                        image={
                                            card.node.acf_blog.blogImage
                                                .sourceUrlSharp.childImageSharp
                                                .fluid
                                        }
                                        link={card.node.slug}
                                        key={card.node.slug}
                                    />
                                ))
                            ) : (
                                <h4 className="headline headline--h4 filter-section__message">
                                    No blogs found
                                </h4>
                            )}
                        </div>
                        <Pagination
                            simple
                            pageSize={postsPerPage}
                            onChange={paginate}
                            total={filterData.length}
                            prevIcon={'<'}
                            nextIcon={'>'}
                            className="filter-section__pagination"
                        />
                    </div>
                </Container>
            </Section>
            <Section>
                <BookBanner
                    image={allBlogPosts.book.image}
                    alt={allBlogPosts.book.alt}
                    title={allBlogPosts.book.title}
                />
            </Section>
        </Layout>
    )
}

export default Blog
