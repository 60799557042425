import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const FeaturedCard = ({ title, exerpt, image, category, link }) => {
    return (
        <div className="blog__featured-card">
            <Img fluid={image} className="blog__featured-card-image" />
            <span className="blog__card-label">{category}</span>
            <h2 className="headline headline--h2 blog__featured-card-title">
                {title}
            </h2>
            <p className="blog__featured-card-exerpt paragraph paragraph--dark paragraph--lg">
                {exerpt}
            </p>
            <Link to={link} className="blog__card-cta">
                Read More
            </Link>
        </div>
    )
}

FeaturedCard.propTypes = {}

export { FeaturedCard }
