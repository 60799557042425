import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const BlogCard = ({ image, category, title, link }) => {
    return (
        <Link to={link} className="blog__card">
            <Img fluid={image} className="blog__card-image" />
            <div className="blog__card-content">
                <span className="blog__card-label">{category}</span>
                <h4 className="headline headline--h4 blog__card-title">
                    {title}
                </h4>
                <div className="blog__card-cta">READ MORE</div>
            </div>
        </Link>
    )
}

BlogCard.propTypes = {}

export { BlogCard }
